<template>
  <div class="app-container merch-card">
    <el-form ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item>
        <el-input
          v-model="queryParams.title"
          placeholder="请输入公告名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.businessName"
          placeholder="请输入供应商名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item class="minItem">
        <el-date-picker
          v-model="datelist"
          type="datetimerange"
          range-separator="至"
          start-placeholder="请选择开始日期"
          end-placeholder="请选择结束日期"
          unlink-panels
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery" style="width: 80px">搜索</el-button>
        <el-button @click="handleReset" style="width: 80px" plain>重置</el-button>
      </el-form-item>
    </el-form>
    <div class="sign">
      <!-- 左边 -->
      <div class="rightBtn">
        <div style="display: flex">
          <div
            v-for="(item, index) in statusList"
            :key="index"
            class="defaultStyle"
            :class="queryParams.status == item.id ? 'opp' : ''"
            @click="StatusValue(item)"
          >
            {{ item.Name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div
        class="merch-table scorr-roll"
        :style="`max-height: ` + mainHright + `px`"
    >
        <div>
        <el-table border :data="searchData" style="width: 100%" v-loading="loading" :header-cell-style="{ background: '#F2F3F5' }">
            <el-table-column align="center" label="序号" width="70" key="1">
                <template slot-scope="scope">
                    <span>{{ scope.row.index }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="发布供应商" prop="businessName" key="2"></el-table-column>
            <el-table-column align="center" label="公告标题" prop="title" key="3"></el-table-column>
            <el-table-column align="center" label="发布时间" prop="createTime" key="4"></el-table-column>
            <el-table-column v-if="queryParams.status == 3 || queryParams.status == 2" align="center" label="审核时间" prop="auditTime" key="5"></el-table-column>
            <el-table-column align="center" label="状态" prop="re_status" key="6">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.status | re_status }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="300" key="7">
                <template slot-scope="scope">
                    <span
                        class="btsed"
                        @click="Submitreview(scope.row)"
                        >详情</span
                    >
                </template>
            </el-table-column>
        </el-table>
        </div>
    </div>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        :current-page="queryParams.page"
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  data() {
    return {
      // 加载状态
      loading: false,
      // 总条数
      total: 0,
      //活动商品状态
      statusList: [
        { Name: "全部", id: 4 },
        { Name: "待审核", id: 1 },
        { Name: "审核通过", id: 2 },
        { Name: "审核驳回", id: 3 },
      ],
      datelist:[],
      queryParams: {
        page: 1,
        status:4,
        title:'',
        businessName:'',
        startTime:'',
        endTime:''
      },
      searchData: [],
    };
  },
  filters: {
    re_status(v) {
      switch (v) {
        case 1:
          return "待审核";
        case 2:
          return "审核通过";
        case 3:
          return "审核驳回";
      }
    },
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction", "detailsSeach"]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.queryParams = this.detailsSeach[router];
      this.datelist=this.detailsSeach[router].datelist
    }
  },
  mounted(){
    this.getList();
  },
  methods: {
    ...commonIndex.mapState(["mainHright"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    ...mapActions(["getAfficheList"]),
    handleReset() {
      this.queryParams= {
        page: 1,
        status:4,
        title:'',
        businessName:'',
        startTime:'',
        endTime:''
      }
      this.datelist = []
      this.getList();
    },
    handleCurrentChange(e) {
      this.queryParams.page = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      if(this.datelist && this.datelist.length>0){
        this.queryParams.startTime = this.datelist[0];
        this.queryParams.endTime = this.datelist[1];
      }else{
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      }
      let res = await this.getAfficheList(this.queryParams);
      this.loading = false;
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.queryParams };
      save.datelist=this.datelist
      save.router = router;
      this.changeSeach(save);
      res.data.data.forEach((v, i) => {
        let index = (res.data.currentPage - 1) * res.data.perPage + i + 1;
        this.$set(v, "index", index);
      });
      this.searchData = res.data.data;
      this.total = res.data.total;
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 点击标记 */
    StatusValue(item) {
      this.queryParams.status = item.id;
      if (this.queryParams.status == "") {
        this.queryParams.status = null;
      }
      this.queryParams.page = 1;
      this.getList();
    },
    /** 审核/详情 */
    Submitreview(row) {
      this.$router.push({
        path: "/announcementDetail",
        query: { id: row.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}
.el-form-item {
  margin-bottom: 10px;
}
.merch-table {
  margin-top: 1%;
  width: 100%;
  height: 77%;
}
.merch-page {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: right;
}
.sign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.opp {
  color: #06b7ae;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgba($color: #2e3238, $alpha: 0.05);
}
.defaultStyle {
  width: 100px;
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 2%;
  margin-top: 1%;
}
</style>
